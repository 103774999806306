import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import {
  PlusCircle,
  Calendar,
  Gift,
  Heart,
  Sun,
  Moon,
  Edit,
  Trash2,
  X,
} from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { parse, format, isValid } from 'date-fns';

const GrandkidsTracker = () => {
  const { pageId } = useParams();
  const navigate = useNavigate();

  const [families, setFamilies] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  const [newFamilyName, setNewFamilyName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addingChildToFamily, setAddingChildToFamily] = useState(null);
  const [editingChild, setEditingChild] = useState(null);
  const [newChild, setNewChild] = useState({
    name: '',
    birthday: '',
    gender: '',
    interests: '',
    grade: '',
  });

  const colors = {
    background: darkMode ? 'bg-slate-900' : 'bg-white',
    textPrimary: darkMode ? 'text-white' : 'text-gray-900',
    buttonPrimary: 'bg-indigo-600',
    buttonSecondary: 'bg-green-600',
    card: darkMode ? 'bg-slate-800' : 'bg-gray-100',
    input: darkMode
      ? 'bg-gray-700 border-gray-600 text-white'
      : 'bg-white border-gray-300 text-gray-900',
  };

  const fetchFamilies = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!pageId) return;

      const { data, error: fetchError } = await supabase
        .from('pages')
        .select('families')
        .eq('id', pageId)
        .single();

      if (fetchError) {
        console.error('Error fetching data:', fetchError);
        setError(fetchError.message);
        return;
      }

      if (data) {
        setFamilies(data.families || []);
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFamilies();
  }, [pageId]);

  useEffect(() => {
    if (!pageId) return;

    const channel = supabase
      .channel(`pages:${pageId}`)
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'pages',
          filter: `id=eq.${pageId}`,
        },
        (payload) => {
          setFamilies(payload.new.families || []);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [pageId]);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      setDarkMode(JSON.parse(savedMode));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const isValidDate = (dateString) => {
    try {
      const parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
      return isValid(parsedDate) && format(parsedDate, 'MM/dd/yyyy') === dateString;
    } catch {
      return false;
    }
  };

  const addFamily = async () => {
    if (!newFamilyName.trim()) return;

    try {
      const newFamily = {
        id: Date.now(),
        name: newFamilyName.trim(),
        children: [],
      };

      const updatedFamilies = [...families, newFamily];

      if (pageId) {
        const { error: updateError } = await supabase
          .from('pages')
          .update({ families: updatedFamilies })
          .eq('id', pageId);

        if (updateError) throw updateError;
      } else {
        const newPageId = uuidv4();
        const { error: insertError } = await supabase
          .from('pages')
          .insert([{ id: newPageId, families: updatedFamilies }]);

        if (insertError) throw insertError;
        navigate(`/page/${newPageId}`);
      }

      setNewFamilyName('');
      await fetchFamilies();
    } catch (err) {
      console.error('Error updating families:', err);
      setError('Failed to add family. Please try again.');
    }
  };

  const addChild = async (familyId) => {
    if (!newChild.name.trim()) return;

    if (!isValidDate(newChild.birthday)) {
      setError('Please enter a valid date in MM/DD/YYYY format.');
      return;
    }

    try {
      const childData = {
        id: Date.now(),
        ...newChild,
        name: newChild.name.trim(),
      };

      const updatedFamilies = families.map((family) => {
        if (family.id === familyId) {
          return {
            ...family,
            children: [...family.children, childData],
          };
        }
        return family;
      });

      const { error: updateError } = await supabase
        .from('pages')
        .update({ families: updatedFamilies })
        .eq('id', pageId);

      if (updateError) throw updateError;

      setNewChild({
        name: '',
        birthday: '',
        gender: '',
        interests: '',
        grade: '',
      });
      setAddingChildToFamily(null);
      await fetchFamilies();
    } catch (err) {
      console.error('Error adding child:', err);
      setError('Failed to add grandchild. Please try again.');
    }
  };

  const updateChild = async (familyId, childId, updatedChild) => {
    if (!isValidDate(updatedChild.birthday)) {
      setError('Please enter a valid date in MM/DD/YYYY format.');
      return;
    }

    try {
      const updatedFamilies = families.map((family) => {
        if (family.id === familyId) {
          return {
            ...family,
            children: family.children.map((child) =>
              child.id === childId ? updatedChild : child
            ),
          };
        }
        return family;
      });

      const { error: updateError } = await supabase
        .from('pages')
        .update({ families: updatedFamilies })
        .eq('id', pageId);

      if (updateError) throw updateError;

      setEditingChild(null);
      await fetchFamilies();
    } catch (err) {
      console.error('Error updating child:', err);
      setError('Failed to update grandchild. Please try again.');
    }
  };

  const deleteChild = async (familyId, childId) => {
    try {
      const updatedFamilies = families.map((family) => {
        if (family.id === familyId) {
          return {
            ...family,
            children: family.children.filter(
              (child) => child.id !== childId
            ),
          };
        }
        return family;
      });

      const { error: updateError } = await supabase
        .from('pages')
        .update({ families: updatedFamilies })
        .eq('id', pageId);

      if (updateError) throw updateError;

      setEditingChild(null);
      await fetchFamilies();
    } catch (err) {
      console.error('Error deleting child:', err);
      setError('Failed to delete grandchild. Please try again.');
    }
  };

  const calculateAge = (birthday) => {
    if (!isValidDate(birthday)) return { years: 0, months: 0, days: 0 };
    const birthDate = parse(birthday, 'MM/dd/yyyy', new Date());
    const now = new Date();

    let years = now.getFullYear() - birthDate.getFullYear();
    let months = now.getMonth() - birthDate.getMonth();
    let days = now.getDate() - birthDate.getDate();

    if (days < 0) {
      months -= 1;
      days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
    }
    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months, days };
  };

  const isTodayBirthday = (birthday) => {
    if (!isValidDate(birthday)) return false;
    const birthDate = parse(birthday, 'MM/dd/yyyy', new Date());
    const today = new Date();
    return (
      birthDate.getDate() === today.getDate() &&
      birthDate.getMonth() === today.getMonth()
    );
  };

    // New function to get today's birthdays
    const getTodaysBirthdays = () => {
        const todaysBirthdays = [];
    
        families.forEach((family) => {
          family.children.forEach((child) => {
            if (child.birthday && isTodayBirthday(child.birthday)) {
              todaysBirthdays.push(child);
            }
          });
        });
    
        return todaysBirthdays;
      };

  const getUpcomingBirthdays = () => {
    const now = new Date();
    const upcoming = [];

    families.forEach((family) => {
      family.children.forEach((child) => {
        if (child.birthday && isValidDate(child.birthday) && !isTodayBirthday(child.birthday)) {
          const birthDate = parse(child.birthday, 'MM/dd/yyyy', new Date());
          const nextBirthday = new Date(
            now.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
          );
          if (nextBirthday < now) {
            nextBirthday.setFullYear(now.getFullYear() + 1);
          }
          const diff = Math.ceil(
            (nextBirthday - now) / (1000 * 60 * 60 * 24)
          );

          if (diff >= 1 && diff <= 30) {
            upcoming.push({
              ...child,
              daysUntilBirthday: diff,
            });
          }
        }
      });
    });

    return upcoming.sort((a, b) => a.daysUntilBirthday - b.daysUntilBirthday);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getBorderColor = (gender, isBirthday) => {
    if (isBirthday) {
      return 'border-yellow-500';
    }
    if (gender === 'Boy') {
      return 'border-blue-500';
    } else if (gender === 'Girl') {
      return 'border-pink-500';
    } else {
      return '';
    }
  };

  return (
    <div
      className={`${colors.background} ${colors.textPrimary} min-h-screen`}
    >
      {/* Fixed App Name with Dynamic Background */}
      <div
        className={`sticky top-0 text-xl font-bold ${
          darkMode
            ? 'bg-slate-900 text-indigo-300'
            : 'bg-white text-indigo-600'
        } py-4 z-50`}
      >
        <div className="max-w-6xl mx-auto px-4 flex justify-between">
          <span
            onClick={scrollToTop}
            className="cursor-pointer hover:underline flex items-center"
          >
            <Heart className="w-6 h-6 mr-2" />
            BirthdayReminder.me
          </span>
        </div>
      </div>

      {/* Theme Toggle Button */}
      <button
        onClick={() => setDarkMode(!darkMode)}
        className="fixed top-4 right-4 bg-indigo-600 text-white p-2 rounded-full shadow-md hover:bg-indigo-700 transition-all z-50"
        aria-label="Toggle Theme"
      >
        {darkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
      </button>

      <div className="p-6 max-w-3xl mx-auto text-lg">
        {/* <p className="mb-6 text-xl">
          Create a special app for your grandparents to keep them connected with
          your latest activities!
        </p> */}

{/* Upcoming Birthdays Summary */}
{(getTodaysBirthdays().length > 0 || getUpcomingBirthdays().length > 0) && (
  <div className="mb-6 p-4 rounded-lg bg-yellow-100 text-gray-900">
    
    {/* Today's Birthdays */}
    {getTodaysBirthdays().length > 0 && (
      <>
        <h2 className="text-2xl font-semibold mb-2 flex items-center">
          <Gift className="w-6 h-6 mr-2" />
          Today’s Birthdays
        </h2>
        {getTodaysBirthdays().map((child) => (
          <div key={child.id} className="text-lg">
            🎉 Happy Birthday, {child.name}!
          </div>
        ))}
      </>
    )}

    {/* Upcoming Birthdays */}
    {getUpcomingBirthdays().length > 0 && (
      <>
        <h2 className="text-2xl font-semibold mt-4 mb-2 flex items-center">
          <Calendar className="w-6 h-6 mr-2" />
          Upcoming Birthdays
        </h2>
        {getUpcomingBirthdays().map((child) => (
          <div key={child.id} className="text-lg">
            🎉 {child.name} has a birthday in {child.daysUntilBirthday} days!
          </div>
        ))}
      </>
    )}
  </div>
)}



      {/* <div className="mb-6 p-4 rounded-lg bg-yellow-100 text-gray-900">
  <h2 className="text-2xl font-semibold mb-2 flex items-center">
    <Gift className="w-6 h-6 mr-2" />
    Birthdays
  </h2>
  
  {getTodaysBirthdays().length > 0 ? (
    getTodaysBirthdays().map((child) => (
      <div key={child.id} className="text-lg">
        🎉 Happy Birthday, {child.name}!
      </div>
    ))
  ) : (
    <p className="text-lg">No birthdays today.</p>
  )}
  
  {getUpcomingBirthdays().length > 0 ? (
    <>
      <h3 className="text-xl font-semibold mt-4 mb-2 flex items-center">
        <Calendar className="w-6 h-6 mr-2" />
        Upcoming
      </h3>
      {getUpcomingBirthdays().map((child) => (
        <div key={child.id} className="text-lg">
          🎉 {child.name} has a birthday in {child.daysUntilBirthday} days!
        </div>
      ))}
    </>
  ) : (
    <p className="text-lg mt-4">No upcoming birthdays in the next 30 days.</p>
  )}
</div> */}

        {error && (
          <div className="mb-4 p-3 rounded bg-red-500 text-white">
            {error}
          </div>
        )}

        {loading ? (
          <div className="text-center text-xl">Loading families...</div>
        ) : families.length > 0 ? (
          <div className="space-y-6">
            {families.map((family) => (
              <div
                key={family.id}
                className={`p-6 rounded-lg shadow-lg ${colors.card}`}
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-semibold flex items-center">
                    {family.name}
                    <button
                      onClick={() => setAddingChildToFamily(family.id)}
                      className="ml-4 text-green-500 hover:text-green-700"
                      title="Add Grandchild"
                    >
                      <PlusCircle className="w-6 h-6" />
                    </button>
                  </h2>
                </div>
                {family.children && family.children.length > 0 ? (
                  <div className="space-y-4">
                    {family.children.map((child) => {
                      const age = calculateAge(child.birthday);
                      const isBirthday = isTodayBirthday(child.birthday);

                      let formattedBirthday = 'N/A';
                      if (child.birthday && isValidDate(child.birthday)) {
                        try {
                          const parsedDate = parse(
                            child.birthday,
                            'MM/dd/yyyy',
                            new Date()
                          );
                          formattedBirthday = format(parsedDate, 'MM/dd/yyyy');
                        } catch (error) {
                          console.error('Error parsing birthday:', error);
                        }
                      }

                      return (
                        <div
                          key={child.id}
                          className={`relative p-4 rounded-lg ${
                            darkMode ? 'bg-gray-700' : 'bg-white'
                          } border ${getBorderColor(child.gender, isBirthday)}`}
                        >
                          {/* Edit Icon in the Top Right */}
                          <button
                            onClick={() =>
                              setEditingChild({ ...child, familyId: family.id })
                            }
                            className="absolute top-2 right-2 text-blue-500 hover:text-blue-700"
                            aria-label="Edit Grandchild"
                          >
                            <Edit className="w-5 h-5" />
                          </button>

                          <div className="flex-1 px-2 py-1">
  <div className="text-xl font-medium flex items-center">
    <Heart className="w-5 h-5 mr-2 text-pink-500" />
    {child.name}
  </div>
  
  {isBirthday && (
    <div className="text-lg font-bold text-yellow-500 flex items-center">
      🎉 Happy Birthday!
    </div>
  )}
  
  <div className="text-lg flex items-center">
    <Calendar className="w-5 h-5 mr-2 text-gray-500" />
    {formattedBirthday}
  </div>
  
  <div className="text-lg flex items-center">
    <Gift className="w-5 h-5 mr-2 text-gray-500" />
    {age.years}y {age.months}m
  </div>
</div>

                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="text-lg opacity-80">
                    No grandchildren added yet.
                  </p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center mt-6 text-xl">
            No families added yet. Scroll down to add a new family.
          </p>
        )}

        {/* Move Add Family to Bottom */}
        <div className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">Add a New Family</h2>
          <input
            type="text"
            value={newFamilyName}
            onChange={(e) => setNewFamilyName(e.target.value)}
            placeholder="Enter family name"
            className={`w-full p-3 border rounded mb-3 ${colors.input}`}
          />
          <button
            onClick={addFamily}
            className={`w-full p-3 rounded ${colors.buttonPrimary} text-white flex items-center justify-center text-xl`}
          >
            <PlusCircle className="w-6 h-6 mr-2" />
            Add Family
          </button>
        </div>
      </div>

      {/* Add Child Modal */}
      {addingChildToFamily && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`w-full max-w-md p-6 rounded-lg ${colors.card}`}>
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Add New Grandchild</h3>
              <button
                onClick={() => {
                  setAddingChildToFamily(null);
                  setNewChild({
                    name: '',
                    birthday: '',
                    gender: '',
                    interests: '',
                    grade: '',
                  });
                  setError(null);
                }}
                className="text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            {error && (
              <div className="mb-2 p-2 rounded bg-red-500 text-white">
                {error}
              </div>
            )}
            <div className="space-y-3">
              <div>
                <label className="block mb-1">Grandchild's Name</label>
                <input
                  type="text"
                  value={newChild.name}
                  onChange={(e) =>
                    setNewChild({ ...newChild, name: e.target.value })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
              <div>
                <label className="block mb-1">Birthday (MM/DD/YYYY)</label>
                <input
                  type="text"
                  value={newChild.birthday}
                  onChange={(e) =>
                    setNewChild({ ...newChild, birthday: e.target.value })
                  }
                  placeholder="MM/DD/YYYY"
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
              <div>
                <label className="block mb-1">Gender</label>
                <select
                  value={newChild.gender}
                  onChange={(e) =>
                    setNewChild({ ...newChild, gender: e.target.value })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                >
                  <option value="">Select Gender</option>
                  <option value="Boy">Boy</option>
                  <option value="Girl">Girl</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div>
                <label className="block mb-1">Grade</label>
                <input
                  type="text"
                  value={newChild.grade}
                  onChange={(e) =>
                    setNewChild({ ...newChild, grade: e.target.value })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
              <div>
                <label className="block mb-1">Interests</label>
                <textarea
                  value={newChild.interests}
                  onChange={(e) =>
                    setNewChild({ ...newChild, interests: e.target.value })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
            </div>
            <button
              onClick={() => addChild(addingChildToFamily)}
              className={`w-full mt-4 p-3 rounded ${colors.buttonPrimary} text-white text-xl`}
            >
              Add Grandchild
            </button>
          </div>
        </div>
      )}

      {/* Edit Child Modal */}
      {editingChild && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`w-full max-w-md p-6 rounded-lg ${colors.card}`}>
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Edit Grandchild</h3>
              <button
                onClick={() => {
                  setEditingChild(null);
                  setError(null);
                }}
                className="text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            {error && (
              <div className="mb-2 p-2 rounded bg-red-500 text-white">
                {error}
              </div>
            )}
            <div className="space-y-3">
              <div>
                <label className="block mb-1">Grandchild's Name</label>
                <input
                  type="text"
                  value={editingChild.name}
                  onChange={(e) =>
                    setEditingChild({ ...editingChild, name: e.target.value })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
              <div>
                <label className="block mb-1">Birthday (MM/DD/YYYY)</label>
                <input
                  type="text"
                  value={editingChild.birthday}
                  onChange={(e) =>
                    setEditingChild({
                      ...editingChild,
                      birthday: e.target.value,
                    })
                  }
                  placeholder="MM/DD/YYYY"
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
              <div>
                <label className="block mb-1">Gender</label>
                <select
                  value={editingChild.gender}
                  onChange={(e) =>
                    setEditingChild({
                      ...editingChild,
                      gender: e.target.value,
                    })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                >
                  <option value="">Select Gender</option>
                  <option value="Boy">Boy</option>
                  <option value="Girl">Girl</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div>
                <label className="block mb-1">Grade</label>
                <input
                  type="text"
                  value={editingChild.grade}
                  onChange={(e) =>
                    setEditingChild({
                      ...editingChild,
                      grade: e.target.value,
                    })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
              <div>
                <label className="block mb-1">Interests</label>
                <textarea
                  value={editingChild.interests}
                  onChange={(e) =>
                    setEditingChild({
                      ...editingChild,
                      interests: e.target.value,
                    })
                  }
                  className={`w-full p-3 border rounded ${colors.input}`}
                />
              </div>
            </div>
            <div className="flex justify-end mt-4 space-x-4">
              <button
                onClick={() =>
                  updateChild(
                    editingChild.familyId,
                    editingChild.id,
                    editingChild
                  )
                }
                className={`px-4 py-2 rounded ${colors.buttonPrimary} text-white text-lg`}
              >
                Save
              </button>
              <button
                onClick={() =>
                  deleteChild(editingChild.familyId, editingChild.id)
                }
                className={`px-4 py-2 rounded bg-red-600 text-white text-lg`}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <footer className="mt-8 text-center text-lg opacity-75">
        <p>
          Need help? Contact us at{' '}
          <a href="mailto:support@grandkidstracker.com" className="underline">
            support@grandkidstracker.com
          </a>
        </p>
      </footer>
    </div>
  );
};

export default GrandkidsTracker;
